<template>
  <div class="mb-4">
    <v-row justify="center">
      <v-col cols="12">
        <v-row>
          <v-col cols="12" xs="12" sm="12" md="12" lg="7" class="text-center">
            <div class="d-flex align-center mb-4 ml-6">
              <v-icon color="cyan darken-2">fas fa-edit</v-icon>
              <span
                class="primary--text font-weight-bold step3-header ml-2 mr-2"
                >Validate and edit in online excel</span
              >
            </div>
            <div class="mb-4">
              <vue-excel-editor
                ref="excelEditor"
                v-model="excelTableData"
                no-header-edit
                free-select
                :disable-panel-setting="true"
                :page="20"
              >
                <vue-excel-column
                  field="First_Name"
                  label="Employee First Name"
                  :width="tableCellWidth"
                  :validate="validateFirstName"
                />
                <vue-excel-column
                  field="Last_Name"
                  label="Employee Last Name"
                  :width="tableCellWidth"
                  :validate="validateLastName"
                />
                <vue-excel-column
                  field="Email_Address"
                  label="Email Address"
                  :width="tableCellWidth"
                  :validate="validateEmailAddress"
                />
                <vue-excel-column
                  field="Date_Of_Join"
                  label="Date Of Join (YYYY-MM-DD)"
                  :width="tableCellWidth"
                  type="date"
                />
                <vue-excel-column
                  field="Location_Name"
                  label="Location"
                  type="select"
                  :validate="validateLocation"
                  :width="tableCellWidth"
                  :options="locationList"
                />
                <vue-excel-column
                  field="Department_Name"
                  label="Department"
                  type="select"
                  :validate="validateDepartment"
                  :width="tableCellWidth"
                  :options="departmentList"
                />
                <vue-excel-column
                  field="Designation_Name"
                  label="Designation"
                  type="select"
                  :validate="validateDesignation"
                  :width="tableCellWidth"
                  :options="designationList"
                />
                <vue-excel-column
                  field="Title"
                  label="Work Schedule"
                  type="select"
                  :validate="validateWorkSchedule"
                  :width="tableCellWidth"
                  :options="workScheduleList"
                />
                <vue-excel-column
                  field="Employee_Type"
                  label="Employee Type"
                  type="select"
                  :validate="validateEmpType"
                  :width="tableCellWidth"
                  :options="empTypeList"
                />
                <vue-excel-column
                  field="Emp_First_Name"
                  label="Manager"
                  type="select"
                  :validate="validateManager"
                  :width="tableCellWidth"
                  :options="managerList"
                />
                <vue-excel-column
                  field="Nominate_Admin"
                  label="Nominate as
                Admin"
                  type="select"
                  :validate="validateNominate"
                  :width="tableCellWidth"
                  :options="nominateList"
                />
                <vue-excel-column
                  field="URL_Validity"
                  label="URL Validity"
                  :width="tableCellWidth"
                />
                <vue-excel-column
                  field="URL_Validity_Duration"
                  label="URL Validity Duration"
                  type="select"
                  :validate="validateDuration"
                  :width="tableCellWidth"
                  :options="validityList"
                />
              </vue-excel-editor>
            </div>
          </v-col>
          <v-col
            v-if="backendErrors.length === 0"
            cols="12"
            xs="12"
            sm="12"
            md="12"
            lg="5"
            :class="{ 'd-flex flex-column justify-center': errorsCount === 0 }"
          >
            <div v-if="errorsCount !== 0" class="d-flex align-center mt-4">
              <v-icon color="red accent-2">fas fa-times-circle</v-icon>
              <span
                class="primary--text font-weight-bold step3-header ml-2 mr-2"
                >Validation Errors</span
              >
            </div>
            <v-card
              color="lightblue lighten-4"
              min-height="200"
              class="mt-4 card-radius"
            >
              <VuePerfectScrollbar
                class="scroll-area"
                :settings="perfectScrollbarSettings"
              >
                <div class="mb-4 onboard-step3-content">
                  <v-card-text v-if="errorsCount !== 0">
                    <div
                      v-for="(error, i) in tableErrors"
                      :key="i + 'errorContent'"
                    >
                      <div v-if="error.column !== 'Column 2'">
                        <v-card
                          v-if="error.errors.length > 0"
                          color="red lighten-4"
                          class="mt-2 pa-1 cursor_pointer card-radius"
                        >
                          <v-list color="red lighten-4">
                            <v-list-group v-model="error.active">
                              <template #activator>
                                <v-list-item-content>
                                  <v-list-item-title>
                                    <v-icon color="red " class="pt-1"
                                      >fas fa-times-circle</v-icon
                                    >
                                    You have
                                    {{ error.errors.length }} validation
                                    error(s) in {{ error.column }}
                                  </v-list-item-title>
                                </v-list-item-content>
                              </template>

                              <v-list-item
                                v-for="errorItem in error.errors"
                                :key="errorItem.index + error.column + i"
                              >
                                <v-list-item-content>
                                  <v-list-item-title
                                    :class="windowWidth < 400 ? 'caption' : ''"
                                  >
                                    Error in {{ error.column }} and Row
                                    {{ errorItem.index }}
                                  </v-list-item-title>
                                </v-list-item-content>
                              </v-list-item>
                            </v-list-group>
                          </v-list>
                        </v-card>
                        <v-card
                          v-else
                          color="green lighten-4"
                          class="mt-2 pa-6 card-radius"
                        >
                          <v-icon color="green" class="mr-2"
                            >fas fa-check-circle</v-icon
                          >
                          {{ error.column }} have no errors
                        </v-card>
                      </div>
                    </div>
                  </v-card-text>
                  <v-card-text v-else class="text-center">
                    <img
                      width="50%"
                      height="auto"
                      src="../../../assets/images/validation-success.png"
                      alt="onboard validation image"
                      class="mx-auto"
                    />
                    <div
                      class="step3-header font-weight-bold primary--text pl-0 mt-6"
                      style="max-width: 600px"
                    >
                      Imported data looks good
                    </div>
                  </v-card-text>
                </div>
              </VuePerfectScrollbar>
            </v-card>
          </v-col>
          <v-col
            v-else
            cols="12"
            xs="12"
            sm="12"
            md="12"
            lg="5"
            xlg="6"
            class="d-flex flex-column justify-center"
          >
            <v-card
              v-if="successCount > 0"
              color="green lighten-4"
              class="mt-2 pa-4 card-radius"
            >
              <v-icon color="green" class="mr-2">fas fa-check-circle</v-icon>
              {{ successCount }}
              candidate data successfully added
            </v-card>
            <div class="d-flex align-center mt-4">
              <v-icon color="red accent-2">fas fa-times-circle</v-icon>
              <span
                class="primary--text font-weight-bold step3-header ml-2 mr-2"
                >Additional Validation Errors - {{ backendErrors.length }}</span
              >
            </div>
            <v-card
              color="lightblue lighten-4"
              min-height="200"
              class="mt-4 card-radius pa-4"
            >
              <VuePerfectScrollbar
                class="scroll-area"
                :settings="perfectScrollbarSettings"
              >
                <div class="onboard-step3-content pr-3">
                  <div
                    v-for="backendError in backendErrors"
                    :key="backendError.row + backendError.message"
                  >
                    <v-card color="red lighten-4" class="mt-2 pa-4 card-radius">
                      <v-icon color="red" class="mr-2"
                        >fas fa-times-circle</v-icon
                      >
                      Row {{ backendError.row }} : {{ backendError.message }}
                    </v-card>
                  </div>
                </div>
              </VuePerfectScrollbar>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import Vue from "vue";
import VueExcelEditor from "vue-excel-editor";
Vue.use(VueExcelEditor);
// import helper function
import { checkValExistsInList } from "@/helper";

export default {
  name: "BulkImportStep3",
  props: {
    locationList: {
      type: Array,
      required: true,
    },
    departmentList: {
      type: Array,
      required: true,
    },
    designationList: {
      type: Array,
      required: true,
    },
    workScheduleList: {
      type: Array,
      required: true,
    },
    empTypeList: {
      type: Array,
      required: true,
    },
    managerList: {
      type: Array,
      required: true,
    },
    validityList: {
      type: Array,
      required: true,
    },
    excelData: {
      type: Array,
      required: true,
    },
  },
  data: () => ({
    excelTableData: [],
    emailErrors: [],
    firstNameErrors: [],
    lastNameErrors: [],
    departmentError: [],
    locationErrors: [],
    designationErrors: [],
    workScheduleErrors: [],
    employeeTypeErrors: [],
    managerErrors: [],
    nominateErrors: [],
    durationErrors: [],
    backendErrors: [],
    successCount: 0,
    isMounted: false,
    emailIds: [],
    nominateList: ["Yes", "No"],
  }),
  computed: {
    perfectScrollbarSettings() {
      return this.$store.state.smallScrollbarSettings;
    },

    // excel table cell width
    tableCellWidth() {
      return "143px";
    },

    tableErrors() {
      let tableColumn = [
        {
          column: "Column 1",
          errors: this.firstNameErrors,
        },
        {
          column: "Column 2",
          errors: this.lastNameErrors,
        },
        {
          column: "Column 3",
          errors: this.emailErrors,
        },
        {
          column: "Column 5",
          errors: this.locationErrors,
        },
        {
          column: "Column 6",
          errors: this.departmentError,
        },
        {
          column: "Column 7",
          errors: this.designationErrors,
        },
        {
          column: "Column 8",
          errors: this.workScheduleErrors,
        },
        {
          column: "Column 9",
          errors: this.employeeTypeErrors,
        },
        {
          column: "Column 10",
          errors: this.managerErrors,
        },
        {
          column: "Column 11",
          errors: this.nominateErrors,
        },
        {
          column: "Column 12",
          errors: this.durationErrors,
        },
      ];
      return tableColumn;
    },
    // total errors count
    errorsCount() {
      let errCount =
        this.firstNameErrors.length +
        this.lastNameErrors.length +
        this.emailErrors.length +
        this.locationErrors.length +
        this.designationErrors.length +
        this.workScheduleErrors.length +
        this.managerErrors.length +
        this.nominateErrors.length +
        this.departmentError.length +
        this.durationErrors.length +
        this.employeeTypeErrors.length;
      this.$emit("errors-count", errCount);
      return errCount;
    },
    // current window size
    windowWidth() {
      return this.$store.state.windowWidth;
    },
  },
  mounted() {
    this.excelTableData = this.excelData;
    // add record one by one to handle validation on each cell
    for (let i in this.excelTableData) {
      this.$refs.excelEditor.newRecord(this.excelTableData[i]);
    }
    this.isMounted = true;
  },
  methods: {
    getOccurrence(array, value) {
      var count = 0;
      array.forEach((v) => v === value && count++);
      return count;
    },
    validateFirstName(newContent, oldContent, row) {
      let errorArray = JSON.parse(JSON.stringify(this.firstNameErrors));
      let isFirstNameErrorExist = checkValExistsInList(
        errorArray,
        row.index,
        "index"
      );
      if (newContent) {
        const nameRegex = /^[a-z0-9'.-\s]+$/i;
        let validationResult = nameRegex.test(String(newContent).toLowerCase());
        if (validationResult) {
          let firstName = newContent.toString();
          if (firstName.length <= 50) {
            let errIndex = this.firstNameErrors.findIndex(
              (obj) => obj.index === row.index
            );
            if (errIndex > -1) {
              this.firstNameErrors.splice(errIndex, 1);
            }
            return "";
          } else {
            return "The value should not exceed more than 50 characters.";
          }
        } else {
          if (!isFirstNameErrorExist) {
            let errObj = {
              index: row.index,
              value: newContent,
            };
            this.firstNameErrors.push(errObj);
          }
          return "Only alphanumeric, spaces and symbols( ' . - ) are allowed.";
        }
      } else {
        if (!isFirstNameErrorExist) {
          let errObj = {
            index: row.index,
            value: newContent,
          };
          this.firstNameErrors.push(errObj);
        }
        return "This field is required";
      }
    },
    // validate first name with duplication, min-max lengths and regex
    validateLastName(newContent, oldContent, row) {
      let errorArray = JSON.parse(JSON.stringify(this.lastNameErrors));
      let isLastNameErrorExist = checkValExistsInList(
        errorArray,
        row.index,
        "index"
      );
      if (newContent) {
        const nameRegex = /^[a-z0-9'.-\s]+$/i;
        let validationResult = nameRegex.test(String(newContent).toLowerCase());
        if (validationResult) {
          let lastName = newContent.toString();
          if (lastName.length <= 50) {
            let errIndex = this.lastNameErrors.findIndex(
              (obj) => obj.index === row.index
            );
            if (errIndex > -1) {
              this.lastNameErrors.splice(errIndex, 1);
            }
            return "";
          } else {
            return "The value should not exceed more than 50 characters.";
          }
        } else {
          if (!isLastNameErrorExist) {
            let errObj = {
              index: row.index,
              value: newContent,
            };
            this.lastNameErrors.push(errObj);
          }
          return "Only alphanumeric, spaces and symbols( ' . - ) are allowed.";
        }
      } else {
        return "";
      }
    },
    // validate email address
    validateEmailAddress(newContent, oldContent, row) {
      let arrIndex = row.index - 1;
      this.emailIds[arrIndex] = newContent;
      let errorArray = JSON.parse(JSON.stringify(this.emailErrors));
      let isEmailErrorExist = checkValExistsInList(
        errorArray,
        row.index,
        "index"
      );
      if (newContent) {
        /*eslint no-useless-escape: "error"*/
        const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
        let validationResult = emailRegex.test(
          String(newContent.trim()).toLowerCase()
        );
        if (validationResult) {
          let result = this.getOccurrence(this.emailIds, newContent);
          if (result <= 1) {
            let errIndex = this.emailErrors.findIndex(
              (obj) => obj.index === row.index
            );
            if (errIndex > -1) {
              this.emailErrors.splice(errIndex, 1);
            }
            return "";
          } else {
            if (!isEmailErrorExist) {
              let errObj = {
                index: row.index,
                value: newContent,
              };
              this.emailErrors.push(errObj);
            }
            return "Duplicate email address";
          }
        } else {
          if (!isEmailErrorExist) {
            let errObj = {
              index: row.index,
              value: newContent,
            };
            this.emailErrors.push(errObj);
          }
          return "Invalid email address";
        }
      } else {
        if (!isEmailErrorExist) {
          let errObj = {
            index: row.index,
            value: newContent,
          };
          this.emailErrors.push(errObj);
        }
        return "This field is required";
      }
    },
    validateLocation(newContent, oldContent, row) {
      let errorArray = JSON.parse(JSON.stringify(this.locationErrors));
      let isOnboardTypeErrorExist = checkValExistsInList(
        errorArray,
        row.index,
        "index"
      );
      if (newContent) {
        // if the value is available in list, then remove the value from errorsArray if its already there
        if (this.locationList.indexOf(newContent) > -1) {
          let errIndex = this.locationErrors.findIndex(
            (obj) => obj.index === row.index
          );
          if (errIndex > -1) {
            this.locationErrors.splice(errIndex, 1);
          }
          return "";
        }
        // the value is not in list, then push it in errors array
        else {
          if (!isOnboardTypeErrorExist) {
            let errObj = {
              index: row.index,
              value: newContent,
            };
            this.locationErrors.push(errObj);
          }

          return "Invalid Location";
        }
      } else {
        if (!isOnboardTypeErrorExist) {
          let errObj = {
            index: row.index,
            value: newContent,
          };
          this.locationErrors.push(errObj);
        }
        return "This field is required";
      }
    },
    validateDepartment(newContent, oldContent, row) {
      let errorArray = JSON.parse(JSON.stringify(this.departmentError));
      let isOnboardTypeErrorExist = checkValExistsInList(
        errorArray,
        row.index,
        "index"
      );
      if (newContent) {
        // if the value is available in list, then remove the value from errorsArray if its already there
        if (this.departmentList.indexOf(newContent) > -1) {
          let errIndex = this.departmentError.findIndex(
            (obj) => obj.index === row.index
          );
          if (errIndex > -1) {
            this.departmentError.splice(errIndex, 1);
          }
          return "";
        }
        // the value is not in list, then push it in errors array
        else {
          if (!isOnboardTypeErrorExist) {
            let errObj = {
              index: row.index,
              value: newContent,
            };
            this.departmentError.push(errObj);
          }

          return "Invalid Department";
        }
      } else {
        if (!isOnboardTypeErrorExist) {
          let errObj = {
            index: row.index,
            value: newContent,
          };
          this.departmentError.push(errObj);
        }
        return "This field is required";
      }
    },
    validateDesignation(newContent, oldContent, row) {
      let errorArray = JSON.parse(JSON.stringify(this.designationErrors));
      let isOnboardTypeErrorExist = checkValExistsInList(
        errorArray,
        row.index,
        "index"
      );
      if (newContent) {
        // if the value is available in list, then remove the value from errorsArray if its already there
        if (this.designationList.indexOf(newContent) > -1) {
          let errIndex = this.designationErrors.findIndex(
            (obj) => obj.index === row.index
          );
          if (errIndex > -1) {
            this.designationErrors.splice(errIndex, 1);
          }
          return "";
        }
        // the value is not in list, then push it in errors array
        else {
          if (!isOnboardTypeErrorExist) {
            let errObj = {
              index: row.index,
              value: newContent,
            };
            this.designationErrors.push(errObj);
          }

          return "Invalid Designation";
        }
      } else {
        if (!isOnboardTypeErrorExist) {
          let errObj = {
            index: row.index,
            value: newContent,
          };
          this.designationErrors.push(errObj);
        }
        return "This field is required";
      }
    },
    validateWorkSchedule(newContent, oldContent, row) {
      let errorArray = JSON.parse(JSON.stringify(this.workScheduleErrors));
      let isOnboardTypeErrorExist = checkValExistsInList(
        errorArray,
        row.index,
        "index"
      );
      if (newContent) {
        // if the value is available in list, then remove the value from errorsArray if its already there
        if (this.workScheduleList.indexOf(newContent) > -1) {
          let errIndex = this.workScheduleErrors.findIndex(
            (obj) => obj.index === row.index
          );
          if (errIndex > -1) {
            this.workScheduleErrors.splice(errIndex, 1);
          }
          return "";
        }
        // the value is not in list, then push it in errors array
        else {
          if (!isOnboardTypeErrorExist) {
            let errObj = {
              index: row.index,
              value: newContent,
            };
            this.workScheduleErrors.push(errObj);
          }

          return "Invalid Work Schedule";
        }
      } else {
        if (!isOnboardTypeErrorExist) {
          let errObj = {
            index: row.index,
            value: newContent,
          };
          this.workScheduleErrors.push(errObj);
        }
        return "This field is required";
      }
    },
    validateEmpType(newContent, oldContent, row) {
      let errorArray = JSON.parse(JSON.stringify(this.employeeTypeErrors));
      let isOnboardTypeErrorExist = checkValExistsInList(
        errorArray,
        row.index,
        "index"
      );
      if (newContent) {
        // if the value is available in list, then remove the value from errorsArray if its already there
        if (this.empTypeList.indexOf(newContent) > -1) {
          let errIndex = this.employeeTypeErrors.findIndex(
            (obj) => obj.index === row.index
          );
          if (errIndex > -1) {
            this.employeeTypeErrors.splice(errIndex, 1);
          }
          return "";
        }
        // the value is not in list, then push it in errors array
        else {
          if (!isOnboardTypeErrorExist) {
            let errObj = {
              index: row.index,
              value: newContent,
            };
            this.employeeTypeErrors.push(errObj);
          }

          return "Invalid Employee Type";
        }
      } else {
        if (!isOnboardTypeErrorExist) {
          let errObj = {
            index: row.index,
            value: newContent,
          };
          this.employeeTypeErrors.push(errObj);
        }
        return "This field is required";
      }
    },
    validateManager(newContent, oldContent, row) {
      let errorArray = JSON.parse(JSON.stringify(this.managerErrors));
      let isOnboardTypeErrorExist = checkValExistsInList(
        errorArray,
        row.index,
        "index"
      );
      if (newContent) {
        // if the value is available in list, then remove the value from errorsArray if its already there
        if (this.managerList.indexOf(newContent) > -1) {
          let errIndex = this.managerErrors.findIndex(
            (obj) => obj.index === row.index
          );
          if (errIndex > -1) {
            this.managerErrors.splice(errIndex, 1);
          }
          return "";
        }
        // the value is not in list, then push it in errors array
        else {
          if (!isOnboardTypeErrorExist) {
            let errObj = {
              index: row.index,
              value: newContent,
            };
            this.managerErrors.push(errObj);
          }

          return "Invalid Manager";
        }
      } else {
        if (!isOnboardTypeErrorExist) {
          let errObj = {
            index: row.index,
            value: newContent,
          };
          this.managerErrors.push(errObj);
        }
        return "This field is required";
      }
    },
    validateNominate(newContent, oldContent, row) {
      let errorArray = JSON.parse(JSON.stringify(this.nominateErrors));
      let isOnboardTypeErrorExist = checkValExistsInList(
        errorArray,
        row.index,
        "index"
      );
      if (newContent) {
        // if the value is available in list, then remove the value from errorsArray if its already there
        if (this.nominateList.indexOf(newContent) > -1) {
          let errIndex = this.nominateErrors.findIndex(
            (obj) => obj.index === row.index
          );
          if (errIndex > -1) {
            this.nominateErrors.splice(errIndex, 1);
          }
          return "";
        }
        // the value is not in list, then push it in errors array
        else {
          if (!isOnboardTypeErrorExist) {
            let errObj = {
              index: row.index,
              value: newContent,
            };
            this.nominateErrors.push(errObj);
          }

          return "Invalid Field";
        }
      }
      // else {
      //   if (!isOnboardTypeErrorExist) {
      //     let errObj = {
      //       index: row.index,
      //       value: newContent,
      //     };
      //     this.nominateErrors.push(errObj);
      //   }
      //   return "This field is required";
      // }
    },
    validateDuration(newContent, oldContent, row) {
      let errorArray = JSON.parse(JSON.stringify(this.durationErrors));
      let isOnboardTypeErrorExist = checkValExistsInList(
        errorArray,
        row.index,
        "index"
      );
      if (newContent) {
        // if the value is available in list, then remove the value from errorsArray if its already there
        if (this.validityList.indexOf(newContent) > -1) {
          let errIndex = this.durationErrors.findIndex(
            (obj) => obj.index === row.index
          );
          if (errIndex > -1) {
            this.durationErrors.splice(errIndex, 1);
          }
          return "";
        }
        // the value is not in list, then push it in errors array
        else {
          if (!isOnboardTypeErrorExist) {
            let errObj = {
              index: row.index,
              value: newContent,
            };
            this.durationErrors.push(errObj);
          }

          return "Invalid Duration";
        }
      } else {
        if (!isOnboardTypeErrorExist) {
          let errObj = {
            index: row.index,
            value: newContent,
          };
          this.durationErrors.push(errObj);
        }
        return "This field is required";
      }
    },
  },
};
</script>

<style>
.step3-header {
  font-size: 1.3em;
}
.onboard-step3-content {
  overflow-x: hidden;
  max-height: 550px;
}
@media screen and (max-width: 1264px) {
  .onboard-step3-content {
    max-height: 800px !important;
  }
}
.component-content[data-v-cf2e49d2] {
  z-index: 1;
}
.v-application .error {
  background: white !important;
}
</style>
